import React from 'react'
import { Link } from 'react-router-dom'
import bgImg from '../../../images/bg_3.jpg'
import Consult from '../../landing-page-content/consult-discription/consultDiscription'
import FaqSection from '../../landing-page-content/FAQ-section/FaqSection'
import ServicesSub from '../../landing-page-content/services-sub-section/servicesSub'
import Testimony from '../../landing-page-content/testimony-section/testimony'


export default function AboutPage() {
  console.log(true)
  return (
    <>
    <section className="hero-wrap hero-wrap-2"style={{ backgroundImage: `url(${bgImg}` }} data-stellar-background-ratio="0.5">
  <div className="overlay"></div>
  <div className="container">
    <div className="row no-gutters slider-text align-items-end justify-content-center">
      <div className="col-md-9 ftco-animate pb-5 text-center">
        <h1 className="mb-3 bread">About Us</h1>
        <p className="breadcrumbs"><span className="mr-2"><Link to="/" >Home <i className="fa fa-chevron-right"></i></Link></span> <span>About us <i className="fa fa-chevron-right"></i></span></p>
      </div>
    </div>
  </div>
</section>
<ServicesSub />
<Consult />
<Testimony />
<FaqSection />

    </>
  )
}
