import React from 'react'
import img from '../../../images/about.jpg'

export default function Consult() {
    return (
        <section className="ftco-counter img" id="section-counter">
            <div className="container">
                <div className="row no-gutters d-flex">
                    <div className="col-md-6 d-flex">
                        <div className="img d-flex align-self-stretch" style={{ backgroundImage: `url(${img}` }} ></div>
                    </div>
                    <div className="col-md-6 p-3 pl-md-5 py-5 bg-primary">
                        <div className="row justify-content-start pb-3">
                            <div className="col-md-12 heading-section heading-section-white ftco-animate">
                                <h2 className="mb-4">Consult us here in <span>ITSolution</span></h2>
                                <p>Transforming Visions into Digital Reality – Consult with Us for Your Path to Success
                                </p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6 justify-content-center counter-wrap ftco-animate">
                                <div className="block-18 mb-4">
                                    <div className="text">
                                        <strong className="number" data-number="750">1000+</strong>
                                        <span>Project Complete</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 justify-content-center counter-wrap ftco-animate">
                                <div className="block-18 mb-4">
                                    <div className="text">
                                        <strong className="number" data-number="568">100+</strong>
                                        <span>Happy Clients</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 justify-content-center counter-wrap ftco-animate">
                                <div className="block-18 mb-4">
                                    <div className="text">
                                        <strong className="number" data-number="478">100+</strong>
                                        <span>Business Partners</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 justify-content-center counter-wrap ftco-animate">
                                <div className="block-18 mb-4">
                                    <div className="text">
                                        <strong className="number" data-number="780">100+</strong>
                                        <span>IT Consultant</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
