import React from 'react'
import img from '../../../images/bg_1.jpg'
import services from '../../../images/services.svg'
import "./silder.scss"
import developmentSvg from '../../../images/development.svg'
import marketingSvg from '../../../images/AI.svg'

export default function Slider() {
	return (
		<>
			<div className="hero-wrap js-fullheight" style={{ backgroundImage: `url(${img}` }} data-stellar-background-ratio="0.5">
				<div className="overlay"></div>
				<div className="container">
					<div className="row no-gutters slider-text js-fullheight align-items-center" data-scrollax-parent="true">
						<div className="col-md-8 ftco-animate mt-5 pt-md-5" data-scrollax=" properties: { translateY: '70%' }">
							<div className="row">
								<div className="col-md-7">
									<p className="mb-4 pl-md-5 line" data-scrollax="properties: { translateY: '30%', opacity: 1.6 }">Transforming Visions Into Digital Reality</p>
								</div>
							</div>
							<h1 className="mb-4 title-experienc" data-scrollax="properties: { translateY: '30%', opacity: 1.6 }">Experienced for <br />Every Kinds of ITSolution</h1>
							<p><a href="#" className="btn btn-primary px-4 py-3">Read more</a></p>
						</div>
					</div>
				</div>
			</div>
			<section className="ftco-intro">
				<div className="container">
					<div className="row justify-content-end">
						<div className="col-md-7">
							<div className="row no-gutters d-flex align-items-stretch">
								<div className="col-md-4 d-flex align-self-stretch ftco-animate">
									<div className="services-1 color-2">
										<div className="line"></div>
										<div className="icon"><img className='servicesIcon' src={developmentSvg} alt="" /></div>
										<div className="media-body">
											<h3 className="heading mb-3">App &amp; Web Development</h3>
										</div>
									</div>
								</div>
								<div className="col-md-4 d-flex align-self-stretch ftco-animate">
									<div className="services-1">
										<div className="line"></div>
										<div className="icon"><img className='servicesIcon' src={marketingSvg} alt="" /></div>
										<div className="media-body">
											<h3 className="heading mb-3">Artificial Intelligence</h3>
										</div>
									</div>
								</div>
								<div className="col-md-4 d-flex align-self-stretch ftco-animate">
									<div className="services-1 color-1">
										<div className="line"></div>
										<div className="icon"><span className="flaticon-light-bulb"></span></div>
										<div className="media-body">
											<h3 className="heading mb-3">Product Design Strategy</h3>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>


		</>
	)
}
