import React from 'react'
import { Link } from 'react-router-dom'
import bgImg from '../../../images/bg_3.jpg'

export default function CaseStudyPage() {
  return (
    <>
      <section className="hero-wrap hero-wrap-2" style={{ backgroundImage: `url(${bgImg}` }} data-stellar-background-ratio="0.5">
        <div className="overlay"></div>
        <div className="container">
          <div className="row no-gutters slider-text align-items-end justify-content-center">
            <div className="col-md-9 ftco-animate pb-5 text-center">
              <h1 className="mb-3 bread">Case Study</h1>
              <p className="breadcrumbs"><span className="mr-2"><Link to="/" >Home <i className="fa fa-chevron-right"></i></Link></span> <span>Case Study <i className="fa fa-chevron-right"></i></span></p>
            </div>
          </div>
        </div>
      </section>

      <section className="ftco-section">
        <div className="container">
          <div className="row justify-content-center pb-5">
            <div className="col-md-12 heading-section text-center ftco-animate">
              <h2 className="mb-4">Our Strategic <span>Work</span></h2>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-lg-3 ftco-animate">
              <div className="project">
                <div className="img">
                  <img src={require("../../../images/work-1.jpg")} className="img-fluid" alt="Colorlib Template" />
                </div>
                <div className="text text-center">
                  <span>Web Development</span>
                  <h3><a href="project.html">Web Design Concept &amp; Web Analysis</a></h3>
                </div>
                <a href={require("../../../images/work-1.jpg")} className="icon image-popup d-flex justify-content-center align-items-center">
                  <span className="fa fa-expand"></span>
                </a>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 ftco-animate">
              <div className="project">
                <div className="img">
                  <img src={require("../../../images/work-2.jpg")} className="img-fluid" alt="Colorlib Template" />
                </div>
                <div className="text text-center">
                  <span>Native App Development</span>
                  <h3><a href="project.html">Creative Idea Build Up</a></h3>
                </div>
                <a href={require("../../../images/work-2.jpg")} className="icon image-popup d-flex justify-content-center align-items-center">
                  <span className="fa fa-expand"></span>
                </a>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 ftco-animate">
              <div className="project">
                <div className="img">
                  <img src={require("../../../images/work-3.jpg")} className="img-fluid" alt="Colorlib Template" />
                </div>
                <div className="text text-center">
                  <span>Artificial Intelligence & Web3</span>
                  <h3><a href="project.html">AI-Infused Web 3.0 Solutions for Seamless Digital Innovation</a></h3>
                </div>
                <a href={require("../../../images/work-3.jpg")} className="icon image-popup d-flex justify-content-center align-items-center">
                  <span className="fa fa-expand"></span>
                </a>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 ftco-animate">
              <div className="project">
                <div className="img">
                  <img src={require("../../../images/work-4.jpg")} className="img-fluid" alt="Colorlib Template" />
                </div>
                <div className="text text-center">
                  <span>UI/UX Strategy</span>
                  <h3><a href="project.html">Strategy for Enduring Customer Relationships</a></h3>
                </div>
                <a href={require("../../../images/work-4.jpg")} className="icon image-popup d-flex justify-content-center align-items-center">
                  <span className="fa fa-expand"></span>
                </a>
              </div>
            </div>

            <div className="col-md-6 col-lg-3 ftco-animate">
              <div className="project">
                <div className="img">
                  <img src={require("../../../images/work-5.jpg")} className="img-fluid" alt="Colorlib Template" />
                </div>
                <div className="text text-center">
                  <span>Web Development</span>
                  <h3><a href={"project.html"}>Best Solution for Information Technology</a></h3>
                </div>
                <a href={require("../../../images/work-5.jpg")} className="icon image-popup d-flex justify-content-center align-items-center">
                  <span className="fa fa-expand"></span>
                </a>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 ftco-animate">
              <div className="project">
                <div className="img">
                  <img src={require("../../../images/work-6.jpg")} className="img-fluid" alt="Colorlib Template" />
                </div>
                <div className="text text-center">
                  <span>Web Application</span>
                  <h3><a href={"project.html"}>Creative Idea Build Up</a></h3>
                </div>
                <a href={require("../../../images/work-6.jpg")} className="icon image-popup d-flex justify-content-center align-items-center">
                  <span className="fa fa-expand"></span>
                </a>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 ftco-animate">
              <div className="project">
                <div className="img">
                  <img src={require("../../../images/work-7.jpg")} className="img-fluid" alt="Colorlib Template" />
                </div>
                <div className="text text-center">
                  <span>Web Development</span>
                  <h3><a href={"project.html"}>Web Design Concept &amp; Web Analysis</a></h3>
                </div>
                <a href={require("../../../images/work-7.jpg")} className="icon image-popup d-flex justify-content-center align-items-center">
                  <span className="fa fa-expand"></span>
                </a>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 ftco-animate">
              <div className="project">
                <div className="img">
                  <img src={require("../../../images/work-8.jpg")} className="img-fluid" alt="Colorlib Template" />
                </div>
                <div className="text text-center">
                  <span>UI/UX Strategy</span>
                  <h3><a href={"project.html"}>Strategy for Enduring Customer Relationships</a></h3>
                </div>
                <a href={require("../../../images/work-8.jpg")} className="icon image-popup d-flex justify-content-center align-items-center">
                  <span className="fa fa-expand"></span>
                </a>
              </div>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col text-center">
              <div className="block-27">
                <ul>
                  <li><a href="#">&lt;</a></li>
                  <li className="active"><span>1</span></li>
                  <li><a href="#">2</a></li>
                  <li><a href="#">3</a></li>
                  <li><a href="#">4</a></li>
                  <li><a href="#">5</a></li>
                  <li><a href="#">&gt;</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

    </>
  )
}
