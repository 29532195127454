import React from 'react'
import bgImg from '../../../images/bg_2.jpg'
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { postContact } from '../../../Services/actionServices/contactApiAction';
// import 'react-phone-number-input/style.css'
// import PhoneInput  from 'react-phone-number-input'
import { isValidPhoneNumber } from 'react-phone-number-input'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import './contactUs.scss'

export default function ContactUs() {
	const dispatch = useDispatch()
	
	return (
		<section className="ftco-appointment ftco-section img" style={{ backgroundImage: `url(${bgImg}` }}>
			<div className="overlay"></div>
			<div className="container">
				<div className="row">
					<div className="col-md-6 half ftco-animate">
						<h2 className="mb-4">Don't hesitate to contact us</h2>
						{/* <form action="#" className="appointment">
							<div className="row">
								<div className="col-md-6">
									<div className="form-group">
										<input type="text" className="form-control" placeholder="Your Name" />
									</div>
								</div>
								<div className="col-md-6">
									<div className="form-group">
										<input type="text" className="form-control" placeholder="Email" />
									</div>
								</div>
								<div className="col-md-12">
									<div className="form-group">
										<div className="form-field">
											<div className="select-wrap">
												<div className="icon"><span className="fa fa-chevron-down"></span></div>
												<select name="" id="" className="form-control">
													<option value="">Services</option>
													<option value="">Web Development</option>
													<option value="">Database Analysis</option>
													<option value="">Server Security</option>
													<option value="">UX/UI Strategy</option>
													<option value="">Branding</option>
													<option value="">Applications</option>
												</select>
											</div>
										</div>
									</div>
								</div>
								<div className="col-md-12">
									<div className="form-group">
										<textarea name="" id="" cols="30" rows="7" className="form-control"
											placeholder="Message"></textarea>
									</div>
								</div>
								<div className="col-md-12">
									<div className="form-group">
										<input type="submit" value="Send message" className="btn btn-primary py-3 px-4" />
									</div>
								</div>
							</div>
						</form> */}
						<Formik
							initialValues={{
								fullName: '',
								email: '',
								subject: '',
								message: '',
								number: ''

							}}
							validationSchema={Yup.object().shape({
								fullName: Yup.string()
									.required('Full name is required'),
								email: Yup.string()
									.email('Email is invalid')
									.required('Email is required'),
								subject: Yup.string()
									.required('Service is required'),
								message: Yup.string()
									.required('Message is required'),
								number: Yup.string()
									.required('Contact no. is required')
									
									

							})}
							onSubmit={fields => {
								console.log(fields);
								dispatch(postContact(fields))
								// get(fields).then(e=>{
								// 	console.log(e);
								// })
							}}
						>
							{({
								handleSubmit,
								handleChange,
								handleBlur,
								values,
								touched,
								isValid,
								errors,
							}) => (
								<Form className="appointment">
									<div className="row">
										<div className="form-group">
											<Field name="fullName" type="text" placeholder="Name" className={'form-control' + (errors.fullName && touched.fullName ? ' is-invalid' : '')} />
											<ErrorMessage name="fullName" component="div" className="invalid-feedback" />
										</div>
										<div className="col-md-6">

											<div className="form-group phoneInput">
												{/* <Field name="number" component={MyInput} /> */}
												<Field name="number" className='form-control' >
													{({
														field, // { name, value, onChange, onBlur }
														form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
														meta,
													}) => (
														<PhoneInput
														className={(form.errors.number && form.touched.number ? ' is-invalid' : '')}
															country={'in'}
															placeholder="Enter phone number"
															value={field.value}
															type="text"
															onChange={(value , data) => {
                                                                  console.log(form)
																if (value) {
																	console.warn(isValidPhoneNumber( value.replace(data.dialCode,'') , data.countryCode.toUpperCase()));
																	if( isValidPhoneNumber( value.replace(data.dialCode,'') , data.countryCode.toUpperCase())){

																		form.setFieldValue(field.name, value)
																	}
																	else{
																		form.setFieldError(field.name, 'invalid contact number')
																		// errors.number = 'invalid contact number'
																	}

																}
																else {
																	form.setFieldValue(field.name, "")
																	form.setFieldError(field.name, 'required contact field')

																}

															}}
														/>
													)}
												</Field>
														<ErrorMessage name="number" component="div" className="invalid-feedback" />
											</div>
										</div>
										<div className="col-md-6">
											<div className="form-group">
												<Field name="email" type="text" placeholder="Email" className={'form-control' + (errors.email && touched.email ? ' is-invalid' : '')} />
												<ErrorMessage name="email" component="div" className="invalid-feedback" />
											</div>

										</div>
									</div>
									<div className="form-group">
										<Field name="subject" type="text" placeholder="Subject" className={'form-control' + (errors.subject && touched.subject ? ' is-invalid' : '')} />
										<ErrorMessage name="subject" component="div" className="invalid-feedback" />
									</div>
									<div className="form-group">
										<Field name="message" type="text" placeholder="Message" as="textarea" rows={4} className={'form-control' + (errors.message && touched.message ? ' is-invalid' : '')} />
										<ErrorMessage name="message" component="div" className="invalid-feedback" />
									</div>

									<div className="form-group">
										<input disabled={true} type="submit" value="Send message" className="btn btn-primary py-3 px-4" />
									</div>
								</Form>
							)}
						</Formik>
					</div>
				</div>
			</div>
		</section>
	)
}
