import React from 'react'
import appDevelopmentImg from '../../../images/appDevelopment.svg'
import digitalMarketImg from '../../../images/AI.svg'
import './services.scss'

export default function Services() {
	return (
		<section className="ftco-section services-section">
			<div className="container">
				<div className="row justify-content-center pb-5">
					<div className="col-md-6 heading-section text-center ftco-animate">
						<h2 className="mb-4">Our Exclusive <span>IT Solution</span> Services</h2>
					</div>
				</div>
				<div className="row d-flex no-gutters">
					<div className="col-md-3 d-flex align-self-stretch ftco-animate">
						<div className="media block-6 services d-block">
							<div className="line"></div>
							<div className="icon"><span className="flaticon-web-programming"></span></div>
							<div className="media-body">
								<h3 className="heading mb-3">Web Development</h3>
								<p> Web pages are designed and developed for both individuals as well as organizations.</p>
							</div>
						</div>
					</div>
					<div className="col-md-3 d-flex align-self-stretch ftco-animate">
						<div className="media block-6 services d-block">
							<div className="line"></div>
							<div className="icon"><img className='serviceIcon' src={appDevelopmentImg} alt="" /></div>
							<div className="media-body">
								<h3 className="heading mb-3">App Development</h3>
								<p>The current technology driven world is the right time to empower your business with an
									innovative mobile app.</p>
							</div>
						</div>
					</div>
					<div className="col-md-3 d-flex align-self-stretch ftco-animate">
						<div className="media block-6 services d-block">
							<div className="line"></div>
							<div className="icon"><img className='serviceIcon' src={digitalMarketImg} alt="" /></div>
							<div className="media-body">
								<h3 className="heading mb-3">Artificial Intelligence</h3>
								<p>We have a top-tier team of experts that offers premier AI, Blockchain consulting and Web3 development services</p>
							</div>
						</div>
					</div>
					<div className="col-md-3 d-flex align-self-stretch ftco-animate">
						<div className="media block-6 services d-block">
							<div className="line"></div>
							<div className="icon"><span className="flaticon-presentation"></span></div>
							<div className="media-body">
								<h3 className="heading mb-3">UX/UI Strategy</h3>
								<p>A small river named Duden flows by their place and supplies.</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}
