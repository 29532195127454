import React from 'react'
import bgImg from '../../../images/bg_1.jpg'
import testimonyImg1 from '../../../images/testimony-1.jpg'
import testimonyImg2 from '../../../images/testimony-2.jpg'
import testimonyImg3 from '../../../images/testimony-3.jpg'
import testimonyImg4 from '../../../images/testimony-4.jpg'
import testimonyImg5 from '../../../images/testimony-5.jpg'
import Slider from "react-slick";  
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./testimony.css"

var settings ={
    arrows: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
        {
          breakpoint: 1024,
          settings: {
           slidesToShow: 2,
          }
        },
        {
          breakpoint: 600,
          settings: {
           slidesToShow: 1,
          }
         }
      ]
  }
export default function Testimony() {
    return (
        <section className="ftco-section ftco-no-pb testimony-section" style={{ backgroundImage: `url(${bgImg}` }} >
            <div className="overlay-1"></div>
            <div className="container-fluid">
                <div className="row justify-content-center mb-5 pb-3">
                    <div className="col-md-7 text-center heading-section heading-section-white ftco-animate">
                        <h2 className="mb-4">Our insights &amp; creative ideas</h2>
                    </div>
                </div>
                <div className="row ">
                    <div className="col-md-12 testimonial">
                        <div className="carousel-testimony ">   
                            <Slider {...settings}>
                                <div className="item">
							<div className="testimony-wrap d-flex align-items-stretch"
							    style={{ backgroundImage: `url(${testimonyImg1}` }}	>
								<div className="overlay"></div>
								<div className="text">
									<div className="line"></div>
									<p className="mb-4">Far far away, behind the word mountains, far from the countries
										Vokalia and Consonantia, there live the blind texts.</p>
									<p className="name">Donna Scott</p>
									<span className="position">Marketing Manager</span>
								</div>
							</div>
						</div>
						<div className="item">
							<div className="testimony-wrap d-flex align-items-stretch"
								 style={{ backgroundImage: `url(${testimonyImg2}` }}>
								<div className="overlay"></div>
								<div className="text">
									<div className="line"></div>
									<p className="mb-4">Far far away, behind the word mountains, far from the countries
										Vokalia and Consonantia, there live the blind texts.</p>
									<p className="name">Roger Scott</p>
									<span className="position">Interface Designer</span>
								</div>
							</div>
						</div>
						<div className="item">
							<div className="testimony-wrap d-flex align-items-stretch"
								 style={{ backgroundImage: `url(${testimonyImg3}` }}>
								<div className="overlay"></div>
								<div className="text">
									<div className="line"></div>
									<p className="mb-4">Far far away, behind the word mountains, far from the countries
										Vokalia and Consonantia, there live the blind texts.</p>
									<p className="name">Roger Scott</p>
									<span className="position">UI Designer</span>
								</div>
							</div>
						</div>
						<div className="item">
							<div className="testimony-wrap d-flex align-items-stretch"
								 style={{ backgroundImage: `url(${testimonyImg4}` }}>
								<div className="overlay"></div>
								<div className="text">
									<div className="line"></div>
									<p className="mb-4">Far far away, behind the word mountains, far from the countries
										Vokalia and Consonantia, there live the blind texts.</p>
									<p className="name">Roger Scott</p>
									<span className="position">Web Developer</span>
								</div>
							</div>
						</div>
						<div className="item">
							<div className="testimony-wrap d-flex align-items-stretch"
								 style={{ backgroundImage: `url(${testimonyImg5}` }}>
								<div className="overlay"></div>
								<div className="text">
									<div className="line"></div>
									<p className="mb-4">Far far away, behind the word mountains, far from the countries
										Vokalia and Consonantia, there live the blind texts.</p>
									<p className="name">Roger Scott</p>
									<span className="position">System Analyst</span>
								</div>
							</div>
						</div>
                            </Slider>
          
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
