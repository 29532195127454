
import './App.css';

import {
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";

import Navbar from './Components/header/Navbar';
import Footer from './Components/footer/footer';
import HomePage from './Components/main-pages/home-page/homePage';
import AboutPage from './Components/main-pages/about-page/aboutPage';
import ServicesPage from './Components/main-pages/services-page/servicesPage';
import CaseStudyPage from './Components/main-pages/case-study-page/caseStudyPage';
import BlogPage from './Components/main-pages/blog-page/blogPage';
import ContactPage from './Components/main-pages/contact-page/contactPage';


function App() {
  return (
    
    <Router>
      <Navbar />
      <Routes>
          <Route path="/" exact element={<HomePage/>}></Route>
          <Route path="/about" exact element={<AboutPage/>}></Route>
          <Route path="/services" exact element={<ServicesPage/>}></Route>
          <Route path="/caseStudy" exact element={<CaseStudyPage/>}></Route>
          <Route path="/blog"  exact element={<BlogPage/>}></Route>
          <Route path="/contact"  exact element={<ContactPage/>}></Route>
      </Routes>
      <Footer />
    </Router>
  
  );
}

export default App;
