import React from 'react'
import services from '../../../images/services.svg'

export default function ServicesSub() {
    return (
        <section className="ftco-section">
            <div className="container">
                <div className="row justify-content-center pb-5">
                    <div className="col-lg-6 heading-section text-center ftco-animate">
                        <h2 className="mb-4">More than <span>35+</span> years we provide <span>IT Solution</span></h2>
                        <p>Experience transformative growth as we work together to craft and implement innovative IT solutions, strategically integrating technology to ensure unparalleled success for your business.</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4">
                        <div className="services-2 text-center">
                            <div className="icon">
                                <span className="flaticon-web-programming"></span>
                            </div>
                            <div className="text">
                                <h3>Web Development</h3>
                                <p>Collaborate with Our Web Development Team for Seamless Digital Solutions</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <img src={services} className="img-fluid" alt="" />
                    </div>
                    <div className="col-md-4">
                        <div className="services-2 text-center">
                            <div className="icon">
                                <span className="flaticon-secure"></span>
                            </div>
                            <div className="text">
                                <h3>Server Security</h3>
                                <p>Our Proactive Server Security Solutions</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
