import { takeEvery, put, call } from 'redux-saga/effects'
import { Constant } from '../constant/constant';

async function PostApi(data){ 
    const fetchOptions = {
		method: "POST",
		headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(data)
	};     
    try {      
     const response= await  fetch("http://localhost:8000/contactus/", fetchOptions)
        console.log(response);
       return response
    }
   catch (e) {
        return e;
    } 
  }

function* getProducts(action) {
    const payload = {
        fullName: action.data.fullName,
        email: action.data.email,
        phone: action.data.number,
        subject: action.data.subject,
        message: action.data.message,
    }
    console.log(action);
   const user= yield call(PostApi , payload)
   console.log(user);
   yield put({type: "SET_PRODUCT_LIST", user})
}

function* productSaga() {
    yield takeEvery(Constant.POST_CONTACT, getProducts)
}

export default productSaga;