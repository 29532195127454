import React, { useState } from 'react'
import aboutImg from '../../../images/about.jpg'
import aboutImg1 from '../../../images/about-1.jpg'
import aboutImg2 from '../../../images/about-2.jpg'
import './faqSection.scss'
import AnimateHeight from 'react-animate-height';

export default function FaqSection() {
	const [open, setOpen] = useState(false);
	const [height, setHeight] = useState('auto');
	const [height1, setHeight1] = useState(0);
	const [height2, setHeight2] = useState(0);
	const [height3, setHeight3] = useState(0);
	return (

		<section className="ftco-section bg-light ftco-faqs">
			<div className="container">
				<div className="row">
					<div className="col-lg-6 order-md-last">
						<div className="img img-video d-flex align-self-stretch align-items-center justify-content-center justify-content-md-center mb-4 mb-sm-0"
							style={{ backgroundImage: `url(${aboutImg}` }}>
						</div>
						<div className="d-flex mt-3">
							<div className="img img-2 mr-md-2 w-100" style={{ backgroundImage: `url(${aboutImg1}` }} ></div>
							<div className="img img-2 ml-md-2 w-100" style={{ backgroundImage: `url(${aboutImg2}` }}></div>
						</div>
					</div>

					<div className="col-lg-6">
						<div className="heading-section mb-5 mt-5 mt-lg-0">
							<h2 className="mb-3">Frequently Asks Questions</h2>
						</div>
						<div id="accordion" className="myaccordion w-100" aria-multiselectable="true">
							<div className="card">
								<div className="card-header p-0" id="headingOne">
									<h2 className="mb-0">
										<button onClick={() => {
											setHeight(height === 0 ? 'auto' : 0)
										}} href="#collapseOne"
											className="d-flex py-3 px-4 align-items-center justify-content-between btn btn-link"
											data-parent="#accordion" data-toggle="collapse" aria-expanded={height === 0 ? false : true}
											aria-controls="collapseOne">
											<p className="mb-0">How can we help your business?</p>
											<i className="fa" aria-hidden="true"></i>
										</button>
									</h2>
								</div>
								<AnimateHeight
									duration={500}
									height={height} // see props documentation below
								>
									<div id="collapseOne" role="tabpanel" aria-labelledby="headingOne">

										<div className="card-body py-3 px-0" >
											<ol>
												<li>Tailored website creation to meet your unique business needs</li>
												<li>Engaging and responsive websites using the latest technologies</li>
												<li>Strategic optimization to boost your online visibility and ranking</li>
												<li>Utilization of cutting-edge technologies for optimal performance</li>
												<li>Customized quotes to provide value without compromising quality</li>
											</ol>
										</div>
									</div>
								</AnimateHeight>
							</div>

							<div className="card">
								<div className="card-header p-0" id="headingTwo" role="tab">
									<h2 className="mb-0">
										<button onClick={() => {
											setHeight1(height1 === 0 ? 'auto' : 0)
										}} href="#collapseTwo"
											className="d-flex py-3 px-4 align-items-center justify-content-between btn btn-link"
											data-parent="#accordion" data-toggle="collapse" aria-expanded={height1 === 0 ? false : true}
											aria-controls="collapseTwo">
											<p className="mb-0">How long does it take to build a website?</p>
											<i className="fa" aria-hidden="true"></i>
										</button>
									</h2>
								</div>
								<AnimateHeight

									duration={500}
									height={height1} // see props documentation below
								>
									<div id="collapseTwo" role="tabpanel" aria-labelledby="headingTwo">
										<div className="card-body py-3 px-0">
											<ol>
												<li>Timelines depend on the website's complexity, ranging from weeks for simple sites to months for intricate projects</li>
												<li>Advanced features extend the development, while a streamlined approach expedites the process</li>
												<li>Timely provision of content and design elements accelerates development</li>
												<li>Incorporating responsive design takes extra time but enhances user experience</li>
												<li>Thorough testing and quality assurance are essential for a successful launch</li>
											</ol>
										</div>
									</div>
								</AnimateHeight>
							</div>

							<div className="card">
								<div className="card-header p-0" id="headingThree" role="tab">
									<h2 className="mb-0">
										<button href="#collapseThree" onClick={() => {
											setHeight2(height2 === 0 ? 'auto' : 0)
										}}
											className="d-flex py-3 px-4 align-items-center justify-content-between btn btn-link"
											data-parent="#accordion" data-toggle="collapse" aria-expanded={height2 === 0 ? false : true}
											aria-controls="collapseThree">
											<p className="mb-0">Can I help your IT Solution for business?</p>
											<i className="fa" aria-hidden="true"></i>
										</button>
									</h2>
								</div>
								<AnimateHeight
									duration={500}
									height={height2} // see props documentation below
								>
									<div id="collapseThree" role="tabpanel" aria-labelledby="headingTwo">
										<div className="card-body py-3 px-0">
											<ol>
												<li>Our IT solutions are customized to address your unique business needs</li>
												<li>Work closely with us for personalized and effective solutions</li>
												<li>Benefit from cutting-edge technology and industry expertise</li>
												<li>Receive guidance aligning technology with your business goals</li>
												<li>Stay informed and involved at every stage of solution development</li>
											</ol>
										</div>
									</div>
								</AnimateHeight>
							</div>

							<div className="card">
								<div className="card-header p-0" id="headingFour" role="tab">
									<h2 className="mb-0">
										<button onClick={() => {
											setHeight3(height3 === 0 ? 'auto' : 0)
										}} href="#collapseFour"
											className="d-flex py-3 px-4 align-items-center justify-content-between btn btn-link"
											data-parent="#accordion" data-toggle="collapse" aria-expanded={height3 === 0 ? false : true}
											aria-controls="collapseFour">
											<p className="mb-0">What are those requirements for ITSolutions?</p>
											<i className="fa" aria-hidden="true"></i>
										</button>
									</h2>
								</div>
								<AnimateHeight
									duration={500}
									height={height3} // see props documentation below
								>
									<div id="collapseFour" role="tabpanel" aria-labelledby="headingTwo">
										<div className="card-body py-3 px-0">
											<ol>
												<li>Clearly define the scope and objectives of your project</li>
												<li>Establish a budget range to align solutions with financial parameters</li>
												<li>Communicate any specific timelines or project deadlines</li>
												<li>Identify user requirements and expectations for the solution</li>
												<li>Determine training needs and expectations for ongoing support</li>
											</ol>
										</div>
									</div>
								</AnimateHeight>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>

	)
}
