import React from 'react'
import BlogSection from '../../landing-page-content/blog-section/blogSection'
import Consult from '../../landing-page-content/consult-discription/consultDiscription'
import ContactUs from '../../landing-page-content/contact-us-section/contactUs'
import FaqSection from '../../landing-page-content/FAQ-section/FaqSection'
import Services from '../../landing-page-content/Services-discription/services'
import ServicesSub from '../../landing-page-content/services-sub-section/servicesSub'
import Strategic from '../../landing-page-content/strategic-discription/strategic'
import Testimony from '../../landing-page-content/testimony-section/testimony'
import Slider from '../../landing-page-content/top-section/slider'

export default function HomePage() {
  return (
    <>
    <Slider />
    <ServicesSub />
    <Consult />
    <Services />
    <Strategic />
    <FaqSection />
    <Testimony />
    <BlogSection />
    <ContactUs />
    </>
  )
}
