import React from 'react'
import blockChain from '../../../images/block_chain_blog.jpg'
import AIBlog from '../../../images/ai_blogs.jpeg'
import mobileWebBlog from '../../../images/mobile_web_blog.png'


export default function BlogSection() {
    return (
        <section className="ftco-section bg-light">
            <div className="container">
                <div className="row justify-content-center mb-5 pb-3">
                    <div className="col-md-7 heading-section text-center ftco-animate">
                        <h2>Read our latest blog</h2>
                    </div>
                </div>
                <div className="row d-flex">
                    <div className="col-md-4 d-flex ftco-animate">
                        <div className="blog-entry justify-content-end">
                            <a href="blog-single.html" className="block-20"
                                style={{ backgroundImage: `url(${AIBlog}` }}
                            >
                            </a>
                            <div className="text mt-3 float-right d-block">
                                <div className="d-flex align-items-center pt-2 mb-4 topp">
                                    <div className="one">
                                        <span className="day">01</span>
                                    </div>
                                    <div className="two pl-1">
                                        <span className="yr">2024</span>
                                        <span className="mos">January</span>
                                    </div>
                                </div>
                                <h3 className="heading"><a href="#">Smart World, Smarter Living: How AI-powered IoT will shape our future</a></h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 d-flex ftco-animate">
                        <div className="blog-entry justify-content-end">
                            <a href="blog-single.html" className="block-20"
                                style={{ backgroundImage: `url(${mobileWebBlog}` }}
                            >
                            </a>
                            <div className="text mt-3 float-right d-block">
                                <div className="d-flex align-items-center pt-2 mb-4 topp">
                                    <div className="one">
                                        <span className="day">10</span>
                                    </div>
                                    <div className="two pl-1">
                                        <span className="yr">2023</span>
                                        <span className="mos">March</span>
                                    </div>
                                </div>
                                <h3 className="heading"><a href="#">Appless & Webless? Redefining the Digital Landscape with Converged Experiences</a></h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 d-flex ftco-animate">
                        <div className="blog-entry">
                            <a href="blog-single.html" className="block-20"
                                style={{ backgroundImage: `url(${blockChain}` }}
                            >
                            </a>
                            <div className="text mt-3 float-right d-block">
                                <div className="d-flex align-items-center pt-2 mb-4 topp">
                                    <div className="one">
                                        <span className="day">29</span>
                                    </div>
                                    <div className="two pl-1">
                                        <span className="yr">2021</span>
                                        <span className="mos">June</span>
                                    </div>
                                </div>
                                <h3 className="heading"><a href="#">Quantum Leaps and Blockchain Beats: A Symphony of Unbreakable Security in the Digital Age</a></h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
