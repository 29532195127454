import createSagaMiddleware from "@redux-saga/core";
import { configureStore } from "@reduxjs/toolkit";
import productSaga from "../actionServices/contactApiSaga";
import rootReducer from "../reducerServices/rootReducer";

const sagaMiddleware = createSagaMiddleware();
const store = configureStore(
    {
        reducer:rootReducer,
        middleware:()=>[sagaMiddleware]
    }
    )

    sagaMiddleware.run(productSaga)


export default store;