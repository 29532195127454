import React from "react";
import justDialLogo from "../../images/jd.svg";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <footer className="ftco-footer ftco-footer-2 ftco-section">
      <div className="container">
        <div className="row mb-5">
          <div className="col-md">
            <div className="ftco-footer-widget mb-4">
              <h2 className="ftco-footer-logo">
                Codewise<span>Infotech</span>
              </h2>
              <h2 className="ftco-heading-2 follow-us">Follow Us</h2>
              <ul className="ftco-footer-social list-unstyled mt-2">
                <li className="ftco-animate">
                  <a href="https://m.facebook.com/codewise.infotech.1/">
                    <span className="fa fa-facebook"></span>
                  </a>
                </li>
                <li className="ftco-animate">
                  <a href="https://in.linkedin.com/company/codewiseinfotech">
                    <span className="fa fa-linkedin"></span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md">
            <div className="ftco-footer-widget mb-4 ml-md-5">
              <h2 className="ftco-heading-2">Explore</h2>
              <ul className="list-unstyled">
                <li>
                  <Link to="/about" className="py-2 d-block">
                    About
                  </Link>
                </li>
                <li>
                  <Link to="/contact" className="py-2 d-block">
                    Contact
                  </Link>
                </li>
                <li>
                  <a href="#" className="py-2 d-block">
                    What We Do
                  </a>
                </li>
                <li>
                  <a href="#" className="py-2 d-block">
                    Plans &amp; Pricing
                  </a>
                </li>
                <li>
                  <a href="#" className="py-2 d-block">
                    Refund Policy
                  </a>
                </li>
                <li>
                  <a href="#" className="py-2 d-block">
                    Call Us
                  </a>
                </li>
              </ul>
            </div>
          </div>
          {/* <div className="col-md">
            <div className="ftco-footer-widget mb-4">
              <h2 className="ftco-heading-2">Legal</h2>
              <ul className="list-unstyled">
                <li>
                  <a href="#" className="py-2 d-block">
                    Join Us
                  </a>
                </li>
                <li>
                  <Link to="/blog" className="py-2 d-block">
                    Blog
                  </Link>
                </li>
                <li>
                  <a href="#" className="py-2 d-block">
                    Privacy &amp; Policy
                  </a>
                </li>
                <li>
                  <a href="#" className="py-2 d-block">
                    Terms &amp; Conditions
                  </a>
                </li>
                <li>
                  <a href="#" className="py-2 d-block">
                    Careers
                  </a>
                </li>
                <li>
                  <Link to="/contact" className="py-2 d-block">
                    Contact
                  </Link>
                </li>
              </ul>
            </div>
          </div> */}
          <div className="col-md">
            <div className="ftco-footer-widget mb-4">
              <h2 className="ftco-heading-2">Have a Questions?</h2>
              <div className="block-23 mb-3">
                <ul>
                  <li>
                    <a href="https://www.google.com/maps/place/80,+Old+Palasiya,+New+Palasia,+Indore,+Madhya+Pradesh+452018/@22.726812,75.8940732,21z/data=!4m6!3m5!1s0x3962fd4a0066ee69:0xec1b8710de4220ed!8m2!3d22.726812!4d75.89421!16s%2Fg%2F11c22gt54z?authuser=0&entry=ttu">
                      <span className="icon fa fa-map marker"></span>
                      <span className="text">
                        201-202 Shri Classic Arch 79,Bima Nagar Anand Bazaar Main Road Indore-452018
                      </span>
                    </a>
                  </li>
                  <li>
                    <a href="tel://+917869696156">
                      <span className="icon fa fa-phone"></span>
                      <span className="text">+91 78696-96156</span>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span className="icon fa fa-paper-plane pr-4"></span>
                      <span className="text">info@codewiseinfotech.com</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 text-center"></div>
        </div>
      </div>
    </footer>
  );
}
