import React, { Component, useMemo, useState } from "react";
import { postContact } from "../../../Services/actionServices/contactApiAction";
import { useDispatch, useSelector } from "react-redux";
import bgImg from "../../../images/bg_3.jpg";
import {
  GoogleMap,
  useLoadScript,
  MarkerF,
  DirectionsRenderer,
  useJsApiLoader,
  LoadScript,
} from "@react-google-maps/api";
import { Link } from "react-router-dom";
import "./contactPage.scss";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { get } from "../../../Services/ApiService";
import { isValidPhoneNumber } from "react-phone-number-input";
import PhoneInput from "react-phone-input-2";

export default function ContactPage() {
  const dispatch = useDispatch();
  const contactData = useSelector((state) => state);
  console.warn(contactData);

  const center = useMemo(() => ({ lat: 22.726699, lng: 75.894222 }), []);

  return (
    <>
      <section
        className="hero-wrap hero-wrap-2"
        style={{ backgroundImage: `url(${bgImg}` }}
        data-stellar-background-ratio="0.5"
      >
        <div className="overlay"></div>
        <div className="container">
          <div className="row no-gutters slider-text align-items-end justify-content-center">
            <div className="col-md-9 ftco-animate pb-5 text-center">
              <h1 className="mb-3 bread">Contact us</h1>
              <p className="breadcrumbs">
                <span className="mr-2">
                  <Link to="/">
                    Home <i className="fa fa-chevron-right"></i>
                  </Link>
                </span>{" "}
                <span>
                  Contact us <i className="fa fa-chevron-right"></i>
                </span>
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="ftco-section bg-light">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12">
              <div className="wrapper px-md-4">
                <div className="row mb-5">
                  <div className="col-md-3">
                    <div className="dbox w-100 text-center">
                      <div className="icon d-flex align-items-center justify-content-center">
                        <span className="fa fa-map-marker"></span>
                      </div>
                      <div className="text">
                        <p>
                          <span>Address:</span>201-202 Shri Classic Arch 79,Bima Nagar Anand Bazaar Main Road Indore-452018
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="dbox w-100 text-center">
                      <div className="icon d-flex align-items-center justify-content-center">
                        <span className="fa fa-phone"></span>
                      </div>
                      <div className="text">
                        <p>
                          <span>Phone:</span>{" "}
                          <a href="tel://+917869696156">+91 78696-96156</a>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="dbox w-100 text-center">
                      <div className="icon d-flex align-items-center justify-content-center">
                        <span className="fa fa-paper-plane"></span>
                      </div>
                      <div className="text">
                        <p>
                          <span>Email:</span>{" "}
                          <a href="mailto:info@codewiseinfotech.com">
                            info@codewiseinfotech.com
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="dbox w-100 text-center">
                      <div className="icon d-flex align-items-center justify-content-center">
                        <span className="fa fa-globe"></span>
                      </div>
                      <div className="text">
                        <p>
                          <span>Website:</span>{" "}
                          <a href="https://codewiseinfotech.com">
                            codewiseinfotech.com
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row no-gutters">
                  <div className="col-md-12">
                    <div className="contact-wrap w-100 p-md-5 p-4">
                      <h3 className="mb-4">Contact Us</h3>

                      <Formik
                        initialValues={{
                          fullName: "",
                          email: "",
                          subject: "",
                          message: "",
                          number: "",
                        }}
                        validationSchema={Yup.object().shape({
                          fullName: Yup.string().required(
                            "Full name is required"
                          ),
                          email: Yup.string()
                            .email("Email is invalid")
                            .required("Email is required"),
                          subject: Yup.string().required("Subject is required"),
                          message: Yup.string().required("Message is required"),
                          number: Yup.string().required(
                            "Contact no. is required"
                          ),
                        })}
                        onSubmit={(fields) => {
                          console.log(fields);
                          dispatch(postContact(fields));
                          // get(fields).then(e=>{
                          // })
                        }}
                      >
                        {({
                          handleSubmit,
                          handleChange,
                          handleBlur,
                          values,
                          touched,
                          isValid,
                          errors,
                        }) => (
                          <Form className="contactForm">
                            <div className="form-group">
                              <label className="label" htmlFor="fullName">
                                Full Name
                              </label>
                              <Field
                                name="fullName"
                                type="text"
                                placeholder="Name"
                                className={
                                  "form-control" +
                                  (errors.fullName && touched.fullName
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <ErrorMessage
                                name="fullName"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                            <div className="row">
                              <div className="col-md-6">
                                <div className="form-group tel-input">
                                  {/* <Field name="number" component={MyInput} /> */}
                                  <Field name="number">
                                    {({
                                      field, // { name, value, onChange, onBlur }
                                      form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                                      meta,
                                    }) => (
                                      <>
                                        <label
                                          className="label"
                                          htmlFor="fullName"
                                        >
                                          Contact Number
                                        </label>
                                        <PhoneInput
                                          className={
                                            "form-control" +
                                            (errors.number && touched.number
                                              ? " is-invalid "
                                              : "")
                                          }
                                          country={"in"}
                                          placeholder="Enter phone number"
                                          value={field.value}
                                          type="text"
                                          onChange={(value, data) => {
                                            console.log(form);
                                            if (value) {
                                              console.warn(
                                                isValidPhoneNumber(
                                                  value.replace(
                                                    data.dialCode,
                                                    ""
                                                  ),
                                                  data.countryCode.toUpperCase()
                                                )
                                              );
                                              if (
                                                isValidPhoneNumber(
                                                  value.replace(
                                                    data.dialCode,
                                                    ""
                                                  ),
                                                  data.countryCode.toUpperCase()
                                                )
                                              ) {
                                                form.setFieldValue(
                                                  field.name,
                                                  value
                                                );
                                              } else {
                                                form.setFieldError(
                                                  field.name,
                                                  "invalid contact number"
                                                );
                                                // errors.number = 'invalid contact number'
                                              }
                                            } else {
                                              form.setFieldValue(
                                                field.name,
                                                ""
                                              );
                                              form.setFieldError(
                                                field.name,
                                                "required contact field"
                                              );
                                            }
                                          }}
                                        />
                                      </>
                                    )}
                                  </Field>
                                  <ErrorMessage
                                    name="number"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label className="label" htmlFor="email">
                                    Email
                                  </label>
                                  <Field
                                    name="email"
                                    type="text"
                                    placeholder="Email"
                                    className={
                                      "form-control" +
                                      (errors.email && touched.email
                                        ? " is-invalid "
                                        : "")
                                    }
                                  />
                                  <ErrorMessage
                                    name="email"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="form-group">
                              <label className="label" htmlFor="subject">
                                subject
                              </label>
                              <Field
                                name="subject"
                                type="text"
                                placeholder="Subject"
                                className={
                                  "form-control" +
                                  (errors.subject && touched.subject
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <ErrorMessage
                                name="subject"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                            <div className="form-group">
                              <label className="label" htmlFor="message">
                                message
                              </label>
                              <Field
                                name="message"
                                type="text"
                                placeholder="Message"
                                as="textarea"
                                rows={4}
                                className={
                                  "form-control" +
                                  (errors.message && touched.message
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <ErrorMessage
                                name="message"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>

                            <div className="form-group">
                              <button
                                type="submit"
                                className="btn btn-primary "
                                disabled={true}
                              >
                                Send Massage
                              </button>
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
