import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./Navbar.scss";
import $ from "jquery";
import brandLogo from "../../images/codewiseLogo.svg";
import justDialLogo from "../../images/jd.svg"

export default function Navbar() {
  const [isNavCollapsed, setIsNavCollapsed] = useState(true);
  const [isActive, setIsActive] = useState("home");

  const activeLink = (link) => {
    console.log(link);
    setIsActive(link);
    window.scrollTo(0, 0);
  };

  const location = useLocation();
  useEffect(() => {
    console.log(location.pathname);
    switch (location.pathname) {
      case "/":
        return activeLink("home");
      case "/about":
        return activeLink("about");
      case "/services":
        return activeLink("services");
      case "/caseStudy":
        return activeLink("caseStudy");
      case "/blog":
        return activeLink("blog");
      case "/contact":
        return activeLink("contact");
    }
  }, [location]);
  const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);
  var scrollWindow = function () {
    $(window).scroll(function () {
      var $w = $(this),
        st = $w.scrollTop(),
        navbar = $(".ftco_navbar"),
        sd = $(".js-scroll-wrap");

      if (st > 150) {
        if (!navbar.hasClass("scrolled")) {
          navbar.addClass("scrolled");
        }
      }
      if (st < 150) {
        if (navbar.hasClass("scrolled")) {
          navbar.removeClass("scrolled sleep");
        }
      }
      if (st > 350) {
        if (!navbar.hasClass("awake")) {
          navbar.addClass("awake");
        }

        if (sd.length > 0) {
          sd.addClass("sleep");
        }
      }
      if (st < 350) {
        if (navbar.hasClass("awake")) {
          navbar.removeClass("awake");
          navbar.addClass("sleep");
        }
        if (sd.length > 0) {
          sd.removeClass("sleep");
        }
      }
    });
  };
  scrollWindow();

  return (
    <>
      <div className="wrap">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-12 col-md d-flex align-items-center">
              <p className="mb-0 phone">
                <span className="mailus">Phone no:</span>{" "}
                <a href="#">+91 78696-96156</a> or{" "}
                <span className="mailus">email us:</span>{" "}
                <a href="#">info@codewiseinfotech.com</a>
              </p>
            </div>
            <div className="col-12 col-md d-flex justify-content-md-end">
              <div className="social-media">
                <p className="mb-0 d-flex">
                  <a
                    href="https://m.facebook.com/codewise.infotech.1/"
                    className="d-flex align-items-center justify-content-center"
                  >
                    <span className="fa fa-facebook">
                      <i className="sr-only">Facebook</i>
                    </span>
                  </a>
                  <a
                    href="https://in.linkedin.com/company/codewiseinfotech"
                    className="d-flex align-items-center justify-content-center"
                  >
                    <span className="fa fa-linkedin">
                      <i className="sr-only">LinkedIn</i>
                    </span>
                  </a>
                  {/* <a
                    href="https://www.justdial.com/Indore/Codewise-Infotech-Khajrana"
                    className="d-flex align-items-center justify-content-center"
                  >
                    <span>
                      <img src={justDialLogo} height={27} width={44} alt="" />
                      <i className="sr-only"></i>
                    </span>
                  </a> */}
                  <a
                    href="https://www.google.com/maps/place/80,+Old+Palasiya,+New+Palasia,+Indore,+Madhya+Pradesh+452018/@22.726812,75.8940732,21z/data=!4m6!3m5!1s0x3962fd4a0066ee69:0xec1b8710de4220ed!8m2!3d22.726812!4d75.89421!16s%2Fg%2F11c22gt54z?authuser=0&entry=ttu"
                    className="d-flex align-items-center justify-content-center"
                  >
                    <span className="fa fa-map-marker">
                      <i className="sr-only">GoogleMap</i>
                    </span>
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <nav
        className="navbar navbar-expand-lg navbar-dark ftco_navbar bg-dark ftco-navbar-light"
        id="ftco-navbar"
      >
        <div className="container">
          {/* <a className="navbar-brand" href="index.html">{"</code"}<span>{"wise>"}</span></a> */}
          <Link className="navbar-brand" to="/">
            <img src={brandLogo} height={40} width={180} alt="" />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="ftco-nav"
            aria-expanded={!isNavCollapsed ? true : false}
            onClick={handleNavCollapse}
            aria-label="Toggle navigation"
          >
            <span className="oi oi-menu"></span> Menu
          </button>

          <div
            className={`${isNavCollapsed ? "collapse" : ""} navbar-collapse`}
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav ml-auto">
              <li className={`nav-item ${isActive === "home" ? "active" : ""}`}>
                <Link className="nav-link" to="/">
                  Home
                </Link>
              </li>
              <li
                className={`nav-item ${isActive === "about" ? "active" : ""}`}
              >
                <Link className="nav-link" to="/about">
                  About
                </Link>
              </li>
              <li
                className={`nav-item ${isActive === "services" ? "active" : ""
                  }`}
              >
                <Link className="nav-link" to="/services">
                  Services
                </Link>
              </li>
              {/* <li
                className={`nav-item ${isActive === "caseStudy" ? "active" : ""
                  }`}
              >
                <Link className="nav-link" to="/caseStudy">
                  Case Study
                </Link>
              </li> */}
              <li className={`nav-item ${isActive === "blog" ? "active" : ""}`}>
                <Link className="nav-link" to="/blog">
                  Blog
                </Link>
              </li>
              <li
                className={`nav-item ${isActive === "contact" ? "active" : ""}`}
              >
                <Link className="nav-link" to="/contact">
                  Contact us
                </Link>
              </li>
              {/* <li className="nav-item active" ><a href="about.html" className="nav-link">Home</a></li>
					<li className="nav-item"><a href="about.html" className="nav-link">About</a></li>
					<li className="nav-item"><a href="services.html" className="nav-link">Services</a></li>
					<li className="nav-item"><a href="cases.html" className="nav-link">Case Study</a></li>
					<li className="nav-item"><a href="blog.html" className="nav-link">Blog</a></li>
  <li className="nav-item"><a href="contact.html" className="nav-link">Contact us</a></li> */}
              {/* <li className="nav-item cta"><a href="#" className="nav-link">Free Consultation</a></li> */}
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}
