import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
// import 'https://fonts.googleapis.com/css?family=Montserrat:200,300,400,500,600,700,800&display=swap';
import 'fontawesome-4.7/css/font-awesome.min.css';
import './css/animate.css'
import './css/owl.carousel.min.css'
import './css/owl.theme.default.min.css'
import './css/magnific-popup.css'
import './css/flaticon.css'
import './css/style.css'
import { Provider } from 'react-redux';
import store from './Services/reduxStore/store';


// [
//   "./js/jquery.min.js",
//   "./js/jquery-migrate-3.0.1.min.js",
//   "./js/popper.min.js",
//   "./js/bootstrap.min.js",
//   "./js/jquery.easing.1.3.js",
//   "./js/jquery.waypoints.min.js",
//   "./js/jquery.stellar.min.js",
//   "./js/owl.carousel.min.js",
//   "./js/jquery.magnific-popup.min.js",
//   "./js/jquery.animateNumber.min.js",
//   "./js/scrollax.min.js",
//   "https://maps.googleapis.com/maps/api/js?key=AIzaSyBVWaKrjvy3MaE7SQ74_uJiULgl1JY0H2s&sensor=false",
//   "./js/google-map.js",
//   "./js/main.js",
// ].forEach((url) => {

 
// })




const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <Provider store={store} >
    <App />
  </Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
