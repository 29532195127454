import {Constant} from '../constant/constant'
import { takeEvery, put, call } from 'redux-saga/effects'

export const postContact = (data) =>{
    return {
        type:Constant.POST_CONTACT,
        data
    }
}

