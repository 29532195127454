import React from 'react'
import img from '../../../images/work-1.jpg'
import img1 from '../../../images/work-2.jpg'
import img2 from '../../../images/work-3.jpg'
import img3 from '../../../images/work-4.jpg'




export default function Strategic() {
	return (
		<section className="ftco-section ftco-no-pt">
			<div className="container">
				<div className="row justify-content-center pb-5">
					<div className="col-md-12 heading-section text-center ftco-animate">
						<h2 className="mb-4">Our Strategic <span>Work</span></h2>
					</div>
				</div>
				<div className="row">
					<div className="col-md-6 col-lg-3 ftco-animate">
						<div className="project">
							<div className="img">
								<img src={img} className="img-fluid" alt="Colorlib Template" />
							</div>
							<div className="text text-center">
								<span>Web Development</span>
								<h3><a href="project.html">Web Design Concept &amp; Web Analysis</a></h3>
							</div>
							<a href={img}
								className="icon image-popup d-flex justify-content-center align-items-center">
								<span className="fa fa-expand"></span>
							</a>
						</div>
					</div>
					<div className="col-md-6 col-lg-3 ftco-animate">
						<div className="project">
							<div className="img">
								<img src={img1} className="img-fluid" alt="Colorlib Template" />
							</div>
							<div className="text text-center">
								<span>Mobile Development</span>
								<h3><a href="project.html">Creative Idea Build Up</a></h3>
							</div>
							<a href={img1}
								className="icon image-popup d-flex justify-content-center align-items-center">
								<span className="fa fa-expand"></span>
							</a>
						</div>
					</div>
					<div className="col-md-6 col-lg-3 ftco-animate">
						<div className="project">
							<div className="img">
								<img src={img2} className="img-fluid" alt="Colorlib Template" />
							</div>
							<div className="text text-center">
								<span>Artificial Intelligence & Web3</span>
								<h3><a href="project.html">AI-Infused Web 3.0 Solutions for Seamless Digital Innovation</a></h3>
							</div>
							<a href={img2}
								className="icon image-popup d-flex justify-content-center align-items-center">
								<span className="fa fa-expand"></span>
							</a>
						</div>
					</div>
					<div className="col-md-6 col-lg-3 ftco-animate">
						<div className="project">
							<div className="img">
								<img src={img3} className="img-fluid" alt="Colorlib Template" />
							</div>
							<div className="text text-center">
								<span>UI/UX Strategy</span>
								<h3><a href="project.html">Strategy for Enduring Customer Relationships</a></h3>
							</div>
							<a href={img3}
								className="icon image-popup d-flex justify-content-center align-items-center">
								<span className="fa fa-expand"></span>
							</a>
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}
