import React from 'react'
import { Link } from 'react-router-dom'
import bgImg from '../../../images/bg_3.jpg'
import bgImg1 from '../../../images/image_1.jpg'
import bgImg2 from '../../../images/image_2.jpg'
import bgImg3 from '../../../images/image_3.jpg'
import bgImg4 from '../../../images/image_4.jpg'
import bgImg5 from '../../../images/image_5.jpg'
import bgImg6 from '../../../images/image_6.jpg'
import blockChain from '../../../images/block_chain_blog.jpg'
import AIBlog from '../../../images/ai_blogs.jpeg'
import mobileWebBlog from '../../../images/mobile_web_blog.png'
import cloudDBS from '../../../images/big-data-cloud-computing.png'
import D2M from '../../../images/TA27-COVER.jpg.webp'
import AR from '../../../images/lets-explore-AR.webp'

export default function BlogPage() {
  return (
    <>
      <section className="hero-wrap hero-wrap-2" style={{ backgroundImage: `url(${bgImg}` }} data-stellar-background-ratio="0.5">
        <div className="overlay"></div>
        <div className="container">
          <div className="row no-gutters slider-text align-items-end justify-content-center">
            <div className="col-md-9 ftco-animate pb-5 text-center">
              <h1 className="mb-3 bread">Read our blog</h1>
              <p className="breadcrumbs"><span className="mr-2"><Link to="/" >Home <i className="fa fa-chevron-right"></i></Link></span> <span>Blog <i className="fa fa-chevron-right"></i></span></p>
            </div>
          </div>
        </div>
      </section>


      <section className="ftco-section bg-light">
        <div className="container">
          <div className="row d-flex">
            <div className="col-md-4 d-flex ftco-animate">
              <div className="blog-entry justify-content-end">
                <a href="blog-single.html" className="block-20" style={{ backgroundImage: `url(${AIBlog})` }}>
                </a>
                <div className="text mt-3 float-right d-block">
                  <div className="d-flex align-items-center pt-2 mb-4 topp">
                    <div className="one">
                      <span className="day">01</span>
                    </div>
                    <div className="two pl-1">
                      <span className="yr">2024</span>
                      <span className="mos">January</span>
                    </div>
                  </div>
                  <h3 className="heading"><a href="#">Smart World, Smarter Living: How AI-powered IoT will shape our future</a></h3>
                </div>
              </div>
            </div>
            <div className="col-md-4 d-flex ftco-animate">
              <div className="blog-entry justify-content-end">
                <a href="blog-single.html" className="block-20" style={{ backgroundImage: `url(${mobileWebBlog})` }}>
                </a>
                <div className="text mt-3 float-right d-block">
                  <div className="d-flex align-items-center pt-2 mb-4 topp">
                    <div className="one">
                      <span className="day">29</span>
                    </div>
                    <div className="two pl-1">
                      <span className="yr">2020</span>
                      <span className="mos">June</span>
                    </div>
                  </div>
                  <h3 className="heading"><a href="#">Appless & Webless? Redefining the Digital Landscape with Converged Experiences</a></h3>
                </div>
              </div>
            </div>
            <div className="col-md-4 d-flex ftco-animate">
              <div className="blog-entry">
                <a href="blog-single.html" className="block-20" style={{ backgroundImage: `url(${blockChain})` }}>
                </a>
                <div className="text mt-3 float-right d-block">
                  <div className="d-flex align-items-center pt-2 mb-4 topp">
                    <div className="one">
                      <span className="day">29</span>
                    </div>
                    <div className="two pl-1">
                      <span className="yr">2020</span>
                      <span className="mos">June</span>
                    </div>
                  </div>
                  <h3 className="heading"><a href="#">Quantum Leaps and Blockchain Beats: A Symphony of Unbreakable Security in the Digital Age</a></h3>
                </div>
              </div>
            </div>
            <div className="col-md-4 d-flex ftco-animate">
              <div className="blog-entry justify-content-end">
                <a href="blog-single.html" className="block-20" style={{ backgroundImage: `url(${cloudDBS})` }}>
                </a>
                <div className="text mt-3 float-right d-block">
                  <div className="d-flex align-items-center pt-2 mb-4 topp">
                    <div className="one">
                      <span className="day">13</span>
                    </div>
                    <div className="two pl-1">
                      <span className="yr">2021</span>
                      <span className="mos">April</span>
                    </div>
                  </div>
                  <h3 className="heading"><a href="#">Exploring the Fusion of Cloud Computing and Big Data Wonders</a></h3>
                </div>
              </div>
            </div>
            <div className="col-md-4 d-flex ftco-animate">
              <div className="blog-entry justify-content-end">
                <a href="blog-single.html" className="block-20" style={{ backgroundImage: `url(${AR})` }}>
                </a>
                <div className="text mt-3 float-right d-block">
                  <div className="d-flex align-items-center pt-2 mb-4 topp">
                    <div className="one">
                      <span className="day">25</span>
                    </div>
                    <div className="two pl-1">
                      <span className="yr">2023</span>
                      <span className="mos">December</span>
                    </div>
                  </div>
                  <h3 className="heading"><a href="#">Augmented Reality in customer service: The new standard</a></h3>
                </div>
              </div>
            </div>
            <div className="col-md-4 d-flex ftco-animate">
              <div className="blog-entry">
                <a href="blog-single.html" className="block-20" style={{ backgroundImage: `url(${D2M})` }}>
                </a>
                <div className="text mt-3 float-right d-block">
                  <div className="d-flex align-items-center pt-2 mb-4 topp">
                    <div className="one">
                      <span className="day">08</span>
                    </div>
                    <div className="two pl-1">
                      <span className="yr">2022</span>
                      <span className="mos">May</span>
                    </div>
                  </div>
                  <h3 className="heading"><a href="#">Mechanism and Idea Behind D2M Broadcasting Technology</a></h3>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col text-center">
              <div className="block-27">
                <ul>
                  <li><a href="#">&lt;</a></li>
                  <li className="active"><span>1</span></li>
                  <li><a href="#">2</a></li>
                  <li><a href="#">3</a></li>
                  <li><a href="#">4</a></li>
                  <li><a href="#">5</a></li>
                  <li><a href="#">&gt;</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
