import { Constant } from "../constant/constant";

export const postContactReducer = (data = [], action) => {
    switch (action.type) {
            case "SET_PRODUCT_LIST":
                console.warn("PRODUCT_LIST condition ", action)
                return [action]
        default:
            return data
    }
}